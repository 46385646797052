<template>
  <amf-page id="not-found-page"
            :hasBreadcrumbs="false">

    <template #content>
      <div class="md-layout md-alignment-center-center">
        <md-card class="md-layout-item md-size-30 md-small-size-100 md-primary" md-with-hover>
          <md-card-header>
            <div class="md-title">NOT FOUND</div>
          </md-card-header>

          <md-card-content>
            <p>NOT FOUND PAGE</p>
          </md-card-content>

          <md-card-actions>
            <md-button class="md-raised md-accent">
              ACTION
            </md-button>
            <md-button class="md-raised md-accent">
              ACTION
            </md-button>
          </md-card-actions>
        </md-card>
      </div>
    </template>
  </amf-page>
</template>

<script>
import AmfPage from '@/components/page-templates/AmfPage.vue';

export default {
  name: 'NotFound',
  components: {
    AmfPage,
  },
};
</script>
